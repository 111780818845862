import React from 'react';
import { ProjectLayout } from '../layouts';

export default () => <ProjectLayout
    title='Fluffymap'
    // teaser='Team management tools'
    note='Tech stack: Django, Angular'
    year='2012'
>
    <img src='images/projects/fluffymap/fluffymap_page.jpg' />
    <img src='images/projects/fluffymap/fluffymap_explore.jpg' />
</ProjectLayout>;